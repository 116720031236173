import { ComponentProps, useMemo } from "react";
import { Box, Button, Text } from "grommet";
import { Link, matchPath, useLocation } from "react-router-dom";
import { getRoute } from "routes";

export const MainMenu = () =>
  useMemo(
    () => (
      <Box direction={"row"}>
        <MenuButton label={"Přehled"} route={getRoute.dashboard()} />
        <MenuButton label={"Kontrola Harvestu"} route={getRoute.timeEntries()} />
        <MenuButton label={"Sledovat Ping"} route={getRoute.pingOverview()} />
        <MenuButton label={"Reporty"} route={getRoute.reports()} />
        <MenuButton label={"Odchylky"} route={getRoute.deviations()} />
        <MenuButton label={"Fakturace"} route={getRoute.billings()} />
        <MenuButton label={"Nastavení Projektů"} route={getRoute.projectsSettings()} />
      </Box>
    ),
    []
  );

interface MenuButtonProps {
  label: string;
  route: string;
}

const MenuButton = ({ label, route }: MenuButtonProps) => {
  const { pathname } = useLocation();

  const active = useMemo(() => !!matchPath(pathname, { path: route }), [pathname, route]);
  const a11yTitle = useMemo(() => (active ? `Zobrazená stránka, ${label}` : `Zobrazit stránku ${label}`), [
    active,
    label,
  ]);

  const linkProps = useMemo<ComponentProps<typeof Link>>(
    () => ({ to: () => ({ pathname: route, state: { id: "" } }) }),
    [route]
  );

  return (
    <Button
      as={"div"}
      active={active}
      hoverIndicator="background"
      style={{ display: "inline-flex", alignItems: "center", textAlign: "center" }}
      a11yTitle={a11yTitle}
    >
      <Link {...linkProps} style={{ color: "inherit", textDecoration: "none", padding: "0.3rem 1.3rem" }}>
        <Text>{label}</Text>
      </Link>
    </Button>
  );
};
